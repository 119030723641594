import Stack, {
  onEntryChange,
} from '@shared/contentstack/live-preview/live-preview';
import { ImageGrid } from '@src/cms-components';
import { imageGridMapper } from '@src/cms-components/image-grid/mappers/image-grid.mapper';
import { useCallback, useEffect, useState } from 'react';
import { LPContainer } from '@shared/contentstack';

const AccordionListPreviewPage = (props: any) => {
  const { pageContext } = props;

  const [livePreviewData, setLivePreviewData] = useState<any>({});

  Stack.startLivePreview(true);

  const fetchData = useCallback(async () => {
    const response = (await Stack.getEntry({
      contentTypeUid: 'image_grid',
      url:
        pageContext?.url && pageContext?.url !== '/'
          ? pageContext?.url
          : '/live-preview',
    })) as any;
    const result = response[0][0];
    setLivePreviewData(result);
  }, [pageContext]);

  useEffect(() => {
    onEntryChange(() => fetchData());
  }, [fetchData]);

  return (
    !!livePreviewData && (
      <LPContainer>
        <ImageGrid {...imageGridMapper(livePreviewData)} />
      </LPContainer>
    )
  );
};

export default AccordionListPreviewPage;
